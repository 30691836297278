<template>
    <transition>
        <div class="sa-f-up-attach">
            <div class="sa-f-up-att" v-if="showUpload">
                <input type="file" ref="file" accept="image/*,.pdf,.doc" @change="onFileChange" @input-file="(x,y,z)=>inputFile(x,y,z)" class="sa-d-none" iuploadd="filename" name="filename" :data="{uploadtype:'profilepic'}" :post-action="imgUploadUrl"  v-if="!loader" />
                <!-- <div class="sa-up-btn">
                    <h6 class="sa-profile-at-cnt" @click="selectFile"><i class="icon-attachment"></i> Attach Investigations</h6>
                </div>
                <button class="sa-btn-transparent sa-chat-btn sa-chat-attach" @click="selectFile">
                    <i class="icon-attachment"></i>
                </button> -->
            </div>
            <img src="../../../public/img/loader.svg" alt="loader" class="sa-multi-loader" v-if="loader" />
            <!-- <ul class="sa-up-att-list">
                <li class="" v-for="(filedata, index) in files" :key="index">
                    <a :href="filedata.url" target="_blank" class="" v-if="isImage(getFileExtension(filedata.url))">
                        <img :src="filedata.url" class="sa-img-cover" />
                    </a>
                    <a :href="filedata.url" target="_blank" download v-else>
                        <i :class="`icon-${getFileExtension(filedata.url)}`" class="sa-attach-icon"></i>
                    </a>
                    <span v-if="showName">{{ filedata.file_nme }}</span>
                    <button class="sa-delete sa-btn-transparent" @click="removeFile(filedata)" v-if="showDelete">
                        <i class="icon-close"></i>
                    </button> 
                </li>
            </ul> -->
            <ul class="sa-up-att-list">
                <li class="" v-for="(filedata, index) in files" :key="index">
                    <a :href="filedata.url" target="_blank" class="" v-if="isImage(getFileExtension(filedata.url))">
                        <img :src="filedata.url" class="sa-img-cover" />
                    </a>
                    <a :href="filedata.url" target="_blank" download v-else>
                        <i :class="`icon-${getFileExtension(filedata.url)}`" class="sa-attach-icon"></i>
                    </a>
                    <span v-if="showName">{{ filedata.file_nme }}</span>
                    <button class="sa-delete sa-btn-transparent" @click="removeFile(filedata)" v-if="showDelete">
                        <i class="icon-close"></i>
                    </button> 
                </li>
               <li v-if="showDelete">
                  <a @click="selectFile">
                     <i class="icon-plus"></i>
                  </a>
              </li>
            </ul>
        </div>
    </transition>
</template>
<script>
/*eslint-disable*/ 
    import axios from "axios";
    import _ from 'lodash';
    import { imageUploadUrl } from '../../../config/default.json'
    export default {
        props: ["fileData", "pflag", "showDelete", "showUpload", "showName"],
        data() {
            return {
                icon_addTreat: null,
                file: "",
                fileName: "",
                loader: false,
                valueadded:0,
                imgUploadUrl: imageUploadUrl
            };
        },
        computed: {
            files: function () {
                return this.fileData.filter(_=>_ && _.url);
            }
        },
        methods: {
            getFileExtension(url) {
                let fileType = url.split(".").pop();
                return fileType;
            },
            isImage(ext) {
                if(!ext){
                    return false;
                }
                const imgFtypes = ["jpg", "jpeg", "png", "gif", "bmp", "tif", "tiff", "eps","svg","raw","jfif"]
                return imgFtypes.indexOf(ext) !== -1;
            },
            selectFile() {
                this.$refs.file.click();
            },
            inputFile(newFile, oldFile, prevent) {
                if (newFile && newFile.response && newFile.response.data && newFile.response.data[0] && newFile.response.data[0] && newFile.response.data[0].url) {
                }
                    this.valueadded += 1
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                        this.edit = true;
                    });
                }
                if (!newFile && oldFile) {
                    this.edit = false;
                }
            },
            async onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                this.fileName = files[0].name;
                if(this.checkExisstingData(files[0].name)) {
                    this.loader = true;
                    this.icon_addTreat = files[0].name.split(".")[1] || null;
                    this.createImage(files[0], this.fileName);
                    // this.$parent.setImg(files[0].type)
                    const formData = new FormData();
                    formData.append("filename", files[0]);
                    formData.append("data", { uploadtype: "doc" });
                    const _self = this;
                    let response = await axios.post(this.imgUploadUrl, formData, { headers: { "Content-Type": "multipart/form-data" } });
                    console.log(response);
                    if (response && response.data && response.data.data && response.data.data[0] && response.data.data[0].url) {
                        this.$emit("file-upload-success", response.data.data[0]);
                        this.loader = false;                        
                    }
                }
            },
            checkExisstingData(val){
                if(this.fileData && this.fileData.length > 0){
                    let values = []
                    let data = _.map(this.fileData,function (v){
                    values.push(v.file_nme)
                    })
                    let value = values.includes(val)
                    return !value;
                }else{
                    return true
                }
                
            },
            createImage(file, filename) {
                this.valueadded +=1
                var image = new Image();
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                    // this.icon_addTreat = e.name.split(".")[1] || null;
                    // this.filesList.push({'name':this.fileName,'url': this.file})
                    this.$emit("change", this.file);
                };
                reader.readAsDataURL(file);
            },
            removeFile(filedata) {
                this.$emit('remove-file',filedata)
            },
        },
    };
</script>